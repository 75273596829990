import axios from "axios";
import cloneDeep from "lodash/cloneDeep";

const state = {
  productsAll: null,
  productsStatus: false,
  productsMaxPrice: 50000,
  searchResult: null,
  searchStatus: false,
  sliderIndex: null,
  todayList: null,
};

const getters = {
  getRecomendationToday(state) {
    return state.todayList;
  },
  getSliderIndex(state) {
    return state.sliderIndex;
  },
  getProductsAll(state) {
    return state.productsAll;
  },
  getProductsStatus(state) {
    return state.productsStatus;
  },
  getProductsMaxPrice(state) {
    return state.productsMaxPrice;
  },
  // دریافت نتیج جستوجو
  getSearchResult(state) {
    return state.searchResult;
  },
  // دریافت استاتوس نتیج جستوجو
  getSearchStatus(state) {
    return state.searchStatus;
  },
};

const mutations = {
  setSliderIndex(state, index) {
    state.sliderIndex = index;
  },
  setRecomendationToday(state, list) {
    state.todayList = list;
  },
  setProductsAll(state, products) {
    state.productsAll = products;
  },
  setProductsStatus(state, status) {
    state.productsStatus = status;
  },
  setProductsMaxPrice(state, price) {
    state.productsMaxPrice = price;
  },
  // ست کردن نتایج جستوجو
  setSearchResult(state, list) {
    state.searchResult = list;
  },
  setSearchStatus(state, status) {
    state.searchStatus = status;
  },
};


const actions = {
  // اکشن جستوجو در میان محصولات
  async searchInProducts({ commit }, search) {
    commit("setSearchStatus", true);
    await axios.get("/front/products/search?q=" + search).then((response) => {
      commit("setSearchStatus", false);
      commit("setSearchResult", response.data.data.products);
    });
  },
  async getRecomendationTodayFromServer({ commit }) {
    await axios.get("/front/today-discounts").then((response) => {
      commit(
        "setRecomendationToday",
        response.data.data.response.todayDiscount
      );
    });
  },
  async getProductsDataFromServer({ commit }, payLoad) {
    let query = cloneDeep(payLoad.query);
    delete query.selected_sizes;
    query.attribute_value_id = payLoad.selectedAttributeValueIds;

    commit("setProductsStatus", true);
    try {
      const response = await axios.get(`front/products${payLoad.inIran??''}`, { params: query });
      commit("setProductsAll", response.data.data.products);
      setTimeout(() => {
        document.documentElement.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
      if (response.data.data.priceFilter.max_price > 50000) {
        let max =
          Math.ceil(response.data.data.priceFilter.max_price / 1000) * 1000;
        commit("setProductsMaxPrice", max);
      }
      commit("setProductsStatus", false);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
