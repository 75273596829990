const state = {
  gallery: null,
};

const getters = {
    getGallery(state){
        return state.gallery
    }
};
const mutations = {
    setGallery(state,list){
        state.gallery=list;
    }
};

export default {
  state,
  getters,
  mutations,
};
